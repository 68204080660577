.sidebar_wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: transform 0.5s cubic-bezier(0, 0.52, 0, 1);
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar_wrapper.hide {
  transform: translateX(-100%);
}

.sidebar_wrapper.open {
  transform: translateX(0);
  overflow: hidden;
}

.content-container {
  font-family: "Outfit", sans-serif;
  background-color: white;
  color: rgb(1, 0, 0);
  letter-spacing: 0.3em;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  padding-bottom: 3rem;
  height: 100%;
}

.content-container .item {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 1.5rem;
  padding: 0.8rem;
  cursor: pointer;
  font-size: 3.5rem;
}

.content-container .item a {
  text-decoration: none;
  color: rgb(0, 0, 43);
}

.content-container .item a:hover,
.content-container .item a:active {
  color: rgb(224, 133, 236);
}

.close-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    font-size: 3rem;
}
