.writing-container {
  line-height: 1.6;
  font-family:
}

.writing-main {
  padding: 2rem;
}


.clips {
  padding-left: 1rem;
}

.clips a {
  color: black;
}

.clips a:hover {
  color: rgb(224, 133, 236);
}
