.footer_container {
  width: 100%;
  background-color: rgb(237, 234, 228);
  color: rgb(18, 26, 92);
  font-family: "Outfit", sans-serif;
}

.footer_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon,
.row .icon {
  color: rgb(17, 61, 55);
  font-size: 2rem;
  padding: .5rem;
}

.minimalist_flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(237, 234, 228, 0.3);
}

.minimalist_flex a{
  text-decoration: none;
  cursor: pointer;
}

.minimalist_flex a:hover{
  text-decoration: none;
  cursor: pointer;
  background-color:rgb(17, 61, 55)
}

@media (min-width:600px) {
  .footer_container .minimalist_flex {
      flex-direction: row;
      height: 10rem;
  }
} 

.minimalist_flex a {
  color: black;
  font-size: 1rem;
  padding: 2rem 5rem;
}

