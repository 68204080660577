.main-container {
  width: 100%;
}

/* MAIN FLEX CONTAINER - SMALLER SCREENS */
.main-wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* LARGER SCREENS */
@media only screen and (min-width: 900px)  {
  .main-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

.main-wrapper img {
  width: 80%;
  border: 10px solid plum;
  margin: 1rem;
} 




/* LEFT SIDE */
.image-placeholder{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* RIGHT SIDE - small screens */
.right-side{
  display: flex;
  flex-direction: column;
}

/* LARGE */
@media only screen and (min-width: 900px)  {
  .right-side{
    width: 50%;
  }
}

.right-side .hi{
  font-size: 4rem;
  padding-bottom: 3rem;
}

.right-side .summary{
  font-size: 2rem;
  padding-bottom: 3rem;

}

/* LARGER SCREENS */
@media only screen and (min-width: 900px)  {
  .image-placeholder{
    width: 60%;
  }
}

/* SMALLER SCREENS */
.skills {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  padding-top: 1rem;
}

.type {
  padding-bottom: 1rem;
  font-weight: 700;


}


.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}

.button-wrapper{
  border: 1px solid azure;
  border-style: solid;
  background-color:  rgb(255,255,255);
  color:  black;
  font-family: 'Jost', sans-serif;
  border: 1px solid black;
  text-align: center;
  padding: .5rem;
  margin: 2rem;
  animation: transitionIn;
  width: 10rem;
}

.button-wrapper:hover {
  background-color: black;
  color: rgb(255,255,255);
} 

.button-wrapper a{
  text-decoration: none;
  font-family: 'Outfit', sans-serif;
  font-size: 1.2rem;
  color: black;
}

.button-wrapper a:hover{
  color: rgb(255,255,255);
}