*,
*::before,
*::after {
  box-sizing: border-box;
}

body, html {
  margin: 0px;
  padding: 0px;
  background-color: white;
  font-style: normal;
  letter-spacing: 0.02rem;
  line-height: 1;
  color: black;
  font-family: 'Outfit', sans-serif;
  overflow-x: hidden;
  position: relative;
  animation: transitionIn 0.75s;
}

/* LAYOUT COMPONENT */
.layout-container {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  order: -1;
}

.main-container .main {
  display: flex;
  order: 2;
  flex-grow: 1;
}

.footer {
  width: 100%;
  order: 3;
}

.title {
  color: rgb(18, 26, 92);
  text-transform: uppercase;
  font-family: "outfit", serif;
  padding-left: 1rem;
}




@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}


@font-face {
  font-family: 'Aloevera';
  src: local('Aloevera'), url(./fonts/Aloevera.ttf) format('truetype');

  font-family: 'GirlasFree';
  src: local('GirlasFree'), url(./fonts/GirlasFree.ttf) format('truetype');
}