/* WRAPS HEADER */
.header-container {
  width: 100%;
}

/* FLEXBOX ON MAIN PAGE - WRAPS LOGO + MENU ICON + SIDEBAR */
.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'GirlasFree';
  font-size: 4rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.header-main a{
  color: black;
  text-decoration: none;
  padding: 1rem;
}
