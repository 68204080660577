.about-container {
  width: 100%;
  
}
/* MAIN FLEX CONTAINER - COLUMNS FLEX*/
.about-main {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  line-height: 1;
}

/* FIRST FLEX CONTAINER - ROW FLEX*/
.about-title {
  padding: 0rem 1rem 1rem 0rem;
  color: rgb(18, 26, 92);
  font-size: 1.5rem;
  font-family: "lato", serif;
  font-weight: 700;
  background: linear-gradient(45deg, rgb(12, 26, 53), rgb(16, 100, 177));
  color: transparent;
  background-clip: text;
  text-transform: uppercase;
  padding-bottom: 1rem;
}


.illustration-container {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 2rem;
}

.illustration-container img {
  width: 25%;
}


@media (min-width:600px) {
  .illustration-container img {
    width: 25%;
  }
} 



.technologies-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0rem 3rem;
}

.technology {
  display: inline-block;
  padding: 0.2rem;
  width: 100%;
  font-family: Outfit;
  font-size: 1rem;
}

.prompt {
  box-sizing: border-box;
  color: rgb(18, 26, 92);
  font-size: 1.5rem;
  background: linear-gradient(45deg, rgb(12, 26, 53), rgb(16, 100, 177));
  color: transparent;
  background-clip: text;
  text-align: center;
}


