.projects-container {
  width: 100%;
}

.projects-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: flex-start;
  align-items: center;
}


.project-showcase {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid gray;
  
}


.title-row{
 font-size: 1.5rem;
 padding-bottom: 1rem;
}

.title-row a {
  color: black;
  text-decoration: underline;
  cursor: pointer;
 }


 .overview{
  padding-bottom: 1rem;
 }

 .project-shots{
  width: 100%;
}



.mobile img{
  width: 80%;
}

.desktop img{
  width: 90%;
}


@media (min-width:600px) {
  .mobile img{
    width: 50%;
  }
  
  .desktop img{
    width:70%;
  }
} 

.prompt{
  padding: 1rem;
}